var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-header"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../../public/assets/images/website/zadi/arrow.png")
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: _vm.routeName
        });
      }
    }
  }), _c('p', [_vm._v(_vm._s(_vm.title))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }