<template>
  <div class="book-details-page">
    <template v-if="!isLoadingBook">
      <page-header :title="book?.title" route-name="ZadiBooks" />
      <book-summary-details :book="book" />
      <recent-book-video-section :book="book" v-if="book && book?.summeryVideoUrl"/>
    </template>
    <template v-else>
      <cover-loader is-cover />
    </template>
  </div>
</template>

<script>
import PageHeader from "../../website-components/zadi/shared/PageHeader.vue";
import BookSummaryDetails from "../../website-components/zadi/books/BookSummaryDetails.vue";
import RecentBookVideoSection from "../../website-components/zadi/home/RecentBookVideoSection.vue";
import CoverLoader from "../../website-components/zadi/shared/CoverLoader.vue";
import zadiRepository from "../../repositories/zadiRepository";

export default {
  metaInfo: {
    title: "مشروع زادي | تفاصيل الكتاب ",
  },
  components: { PageHeader, BookSummaryDetails, RecentBookVideoSection, CoverLoader },
  data() {
    return {
      book: null,
      isLoadingBook: false,
    }
  },
  async mounted() {
    this.isLoadingBook = true;
    const bookId = this.$route.params.id
    const res = await zadiRepository.getBookById(bookId);
    this.book = await res?.data;
    this.isLoadingBook = false;
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

.book-details-page {
  padding: 120px 40px 80px 40px;
  font-family: "Tajawal", sans-serif;
}

@media (max-width: 560px) {
  .book-details-page {
    padding: 120px 20px 80px 20px;
  }
}
</style>
