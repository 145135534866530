<template>
  <div class="page-header">
    <img src="../../../../../../public/assets/images/website/zadi/arrow.png" @click="$router.push({ name: routeName })" />
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => null,
    },
    routeName: {
      type: String,
      default: () => 'ZadiHome',
    },
  },
};
</script>

<style scoped>
.page-header {
  width: 100%;
  display: flex;
  column-gap: 26px;
  align-items: center;
  margin-bottom: 35px;
}

.page-header img {
  width: 18px;
  cursor: pointer;
}

p {
  font-size: 22px;
  font-weight: 700;
}
</style>