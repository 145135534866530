<template>
  <div class="recent-book-video-section">
    <home-section-header title="ملخصات زادي" color="#9ED8D3" />
    <book-video :book="book" />
  </div>
</template>

<script>
import BookVideo from "../books/BookVideo.vue";
// import BookVideoSkelton from '../books/BookVideoSkelton.vue';
import HomeSectionHeader from "../shared/HomeSectionHeader.vue";

export default {
  components: { BookVideo, HomeSectionHeader },
  props: {
    book: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style scoped>
.recent-book-video-section {
  /* padding: 60px 40px 0px 40px; */
  padding: 60px 0px 0px 0px;
}

@media (max-width: 560px) {
  .recent-book-video-section {
    /* padding: 60px 20px 0px 20px; */
    padding: 60px 0px 0px 0px;
  }
}
</style>